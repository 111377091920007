import { createFileRoute } from '@tanstack/react-router';
import { lazy } from 'react';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { APPLICATION_TYPE_LABELS } from '@/common/i18n/applicationTypeLabels.ts';
import { getApplicationQuery } from '@/features/administrator-panel/review-application/api/reviewApi.ts';
import { ReviewApplication } from '@/features/administrator-panel/review-application/ReviewApplication.tsx';

export const Route = createFileRoute('/admin/applications/$applicationId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getApplicationQuery(params.applicationId)),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData
          ? `${loaderData.lastName} ${APPLICATION_TYPE_LABELS[loaderData?.applicationType]}`
          : 'Customer Application',
      },
    ],
  }),
  component: ReviewApplication,
  staticData: {
    previewComponent: lazy(() =>
      import(
        '@/components/ui/route-previews/previews/ApplicationPreview.tsx'
        ).then((m) => ({ default: m.ApplicationPreview })),
      ),
  },
});
