import {
  AnyRouteMatch,
  AnyRouter,
  Link,
  LinkComponentProps,
  MakeRouteMatch,
  RegisteredRouter,
  RouteIds,
  useRouter,
} from '@tanstack/react-router';
import { AnimatePresence } from 'motion/react';
import React, { useCallback, useEffect, useState } from 'react';

import {
  DefaultErrorPreview,
  DefaultRoutePreviewFallback,
  RoutePreviewCard,
} from '@/components/ui/route-previews/RoutePreviewCard.tsx';

const PreviewContext = React.createContext<AnyRouteMatch | null>(null);

export const useContextMatch = <
  TId extends RouteIds<TRouter['routeTree']>,
  TRouter extends AnyRouter = RegisteredRouter,
>(): MakeRouteMatch<TRouter['routeTree'], TId> => {
  const match = React.useContext(PreviewContext);
  if (!match) {
    throw new Error('useContextMatch must be used within a Provider');
  }
  return match;
};

const useHoverState = ({ delay }: { delay: number }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);

  const onMouseEnter = () => {
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        setIsHovered(true);
      }, delay),
    );
  };

  const onMouseLeave = () => {
    clearTimeout(timeoutId);
    setIsHovered(false);
  };

  return {
    isHovered,
    onMouseEnter,
    onMouseLeave,
  };
};

export type PreviewLinkProps = LinkComponentProps<'a'> & {
  hoverDelay?: number;
};
export const PreviewLink = ({
  hoverDelay = 800,
  ...props
}: PreviewLinkProps) => {
  const { isHovered, onMouseEnter, onMouseLeave } = useHoverState({
    delay: hoverDelay,
  });
  const [tooltipLocation, setTooltipLocation] = useState({ x: 0, y: 0 });
  const [resolvedMatch, setResolvedMatch] = useState<AnyRouteMatch | null>(
    null,
  );

  const PreviewComponent = resolvedMatch?.staticData?.previewComponent;
  const router = useRouter();

  useEffect(() => {
    (async () => {
      if (isHovered) {
        const matches = router.matchRoutes(router.buildLocation(props));
        if (!matches) {
          return;
        }

        const lastMatch = matches[matches.length - 1];
        if (!lastMatch.staticData.previewComponent) {
          return;
        }
        setResolvedMatch(lastMatch);
      }
    })();
  }, [isHovered, props.to, props.params, props.search]);

  // We don't want to update the location of an already-displayed preview
  const maybeSetTooltipLocation = useCallback(
    (location: { x: number; y: number }) =>
      isHovered && tooltipLocation.x !== 0
        ? undefined
        : setTooltipLocation(location),
    [isHovered, tooltipLocation],
  );
  return (
    <>
      <PreviewContext.Provider value={resolvedMatch}>
        <Link
          {...props}
          onMouseEnter={(e) => {
            onMouseEnter();
            setTooltipLocation({ x: e.clientX, y: e.clientY });
            return props.onMouseOver?.(e);
          }}
          onMouseLeave={(e) => {
            onMouseLeave();
            return props.onMouseOut?.(e);
          }}
          onMouseMove={(e) =>
            maybeSetTooltipLocation({ x: e.clientX, y: e.clientY })
          }
        >
          {(state) => (
            <>
              {typeof props.children === 'function'
                ? props.children(state)
                : props.children}
            </>
          )}
        </Link>
        {PreviewComponent && resolvedMatch && (
          <AnimatePresence>
            {isHovered && (
              <RoutePreviewCard
                matchId={resolvedMatch.id}
                x={tooltipLocation.x}
                y={tooltipLocation.y}
                fallbackComponent={
                  resolvedMatch.staticData.previewFallback ||
                  DefaultRoutePreviewFallback
                }
                errorComponent={DefaultErrorPreview}
              >
                <PreviewComponent />
              </RoutePreviewCard>
            )}
          </AnimatePresence>
        )}
      </PreviewContext.Provider>
    </>
  );
};
