import { createFileRoute, redirect } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { queryApplicationOptions } from '@/features/application-filling/api/queryApplication.ts';
import { Application } from '@/features/application-filling/Application.tsx';
import NotFoundApplicationId from '@/components/ui/applications/NotFoundApplicationId.tsx';
import { ApplicationLoader } from '@/components/ui/applications/ApplicationLoader.tsx';

export const Route = createFileRoute('/application/$applicationId')({
  beforeLoad: async (ctx) => {
    const user = await requireAuthenticated(ctx);

    if (user?.showWelcomePage) {
      throw redirect({
        to: '/welcome',
        search: {
          continueSearch: ctx.search,
          continueTo: ctx.location.pathname,
        },
      });
    }
  },
  loader: async (route) =>
    crownQueryClient.ensureQueryData(
      queryApplicationOptions(route.params.applicationId),
    ),
  component: Application,
  errorComponent: NotFoundApplicationId,
  pendingComponent: ApplicationLoader
});
