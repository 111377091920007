import { createFileRoute } from '@tanstack/react-router'
import { ErrorState } from '@/components/ui/states/ErrorState.tsx';
import Spinner from '@/components/ui/Spinner.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { listUserApplicationsQuery } from '@/features/portal/start-home/api/listApplications.ts';
import { StartHome } from '@/features/portal/start-home/StartHome.tsx';

export const Route = createFileRoute('/portal/start/_guide/')({
  errorComponent: ErrorState,
  pendingComponent: () => (
    <div className={'flex items-center justify-center py-80'}>
      <Spinner className={'size-14'} />
    </div>
  ),
  component: StartHome,
  loader: async () =>
    crownQueryClient.ensureQueryData(listUserApplicationsQuery),
})
