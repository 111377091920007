import { createFileRoute } from '@tanstack/react-router';
import { Login } from '@/features/user-session/Login.tsx';

export const Route = createFileRoute('/login')({
  validateSearch: (
    search: Record<string, unknown>,
  ):
    | Record<string, unknown>
    | {
        redirect: string | undefined;
        redirectSearch: Record<string, unknown> | undefined;
        'descope-login-flow': string | undefined;
        t: string | undefined;
        email: string | undefined,
      } => ({
    redirect: (search.redirect as string) || undefined,
    redirectSearch:
      typeof search.redirectSearch === 'object'
        ? (search.redirectSearch as Record<string, unknown>)
        : undefined,
    'descope-login-flow': (search['descope-login-flow'] as string) || undefined,
    t: (search.t as string) || undefined,
    email: (search.email as string) || undefined
  }),
  head: () => ({ meta: [{ title: 'Login' }] }),
  component: Login,
});
