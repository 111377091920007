import { ErrorBoundary, FallbackRender } from '@sentry/react';
import { motion } from 'motion/react';
import { FC, ReactNode, Suspense, useState } from 'react';
import Measure, { ContentRect } from 'react-measure';

import { Skeleton } from '@/components/ui/Skeleton.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { cn } from '@/lib/utils.ts';

type RoutePreviewCardProps = {
  x: number;
  y: number;
  children?: ReactNode;
  matchId: string;
  fallbackComponent: FC;
  errorComponent: FallbackRender;
};

export const RoutePreviewCard = ({
  x,
  y,
  children,
  fallbackComponent: FallbackComponent,
  errorComponent: ErrorComponent,
  matchId,
}: RoutePreviewCardProps) => {
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleResize = ({ bounds }: ContentRect) => {
    setContainerDimensions({
      width: bounds!.width,
      height: bounds!.height,
    });
  };
  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <motion.div
          key={matchId}
          style={{
            left: x - (containerDimensions.width / 2 || 0),
            top: y + 20,
          }}
          className={cn(
            'fixed p-3 bg-card border border-muted rounded-lg z-50 shadow-lg text-left',
            {
              '!invisible': containerDimensions.width === 0,
            },
          )}
          ref={measureRef}
          exit={{ opacity: 0, scale: 0.95 }}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <ErrorBoundary fallback={ErrorComponent}>
            <Suspense fallback={<FallbackComponent />}>{children}</Suspense>
          </ErrorBoundary>
        </motion.div>
      )}
    </Measure>
  );
};

export const DefaultRoutePreviewFallback = () => {
  return (
    <div>
      <Skeleton className={'mb-3 block h-6 w-64'} />
      <Skeleton className={'mb-1 block h-4 w-48'} />
      <Skeleton className={'block h-4 w-24'} />
    </div>
  );
};

export const DefaultErrorPreview = () => {
  return (
    <div className={'flex items-center justify-center'}>
      <Typography className={'text-center italic text-muted-foreground'}>
        Unable to load preview
      </Typography>
    </div>
  );
};
