import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { queryApplicationOptions } from '@/features/provider-panel/application-filling/api/queryApplication.ts';
import { fullName } from '@/lib/fullName.ts';
import { Application } from '@/features/provider-panel/application-filling/Application.tsx';

export const Route = createFileRoute(
  '/provider/$providerId/applications/$applicationId',
)({
  loader: async ({ params }) =>
    crownQueryClient.ensureQueryData(
      queryApplicationOptions(params.providerId, params.applicationId),
    ),
  head: ({ loaderData: application }) => ({
    meta: [{ title: application ? fullName(application) : 'Application' }],
  }),
  component: Application,
});
