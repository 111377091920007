export const fullName = (
  {
    firstName,
    lastName,
    middleName: _middleName,
  }: {
    firstName: string;
    lastName: string;
    middleName?: string;
  },
  middleInitial = false,
) => {
  const middleName =
    middleInitial && _middleName ? _middleName?.charAt(0) : _middleName;
  return `${firstName} ${middleName ? middleName + ' ' : ''}${lastName}`;
};
