import { useUserSession } from "@/common/user-session/useUserSession";
import NotFound from "@/components/ui/states/NotFound";

const NotFoundDepositAccount = () => {
  const { user } = useUserSession();
  const email = user?.email;
  return <NotFound text="We couldn't find the deposit account"
    additionalComponent={email ? <p>Logged in as <b>{email}</b></p> : null}
  />
};

export default NotFoundDepositAccount;