import { Typography } from '@/components/ui/Typography';
import { CloudX } from '@phosphor-icons/react';


interface NotFoundProps {
  text: string;
  additionalComponent?: React.ReactNode;
}

const NotFound = ({ text, additionalComponent }: NotFoundProps) => {
  return (
    <div
      className={
        'flex w-full flex-col items-center justify-center gap-y-2 py-60'
      }
    >
      <div className={'flex items-center justify-center gap-x-4'}>
        <CloudX className={'size-14 text-error'} />
        <Typography variant={'h3'} className={'text-error'}>
          {text}
        </Typography>
      </div>
      {additionalComponent && (
        <div className={'flex items-center justify-center gap-x-4'}>
          {additionalComponent}
        </div>
      )}
    </div>
  );
};

export default NotFound;