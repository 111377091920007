import { ApplicationType } from '@/features/application-filling/types.ts';

export const APPLICATION_TYPE_LABELS: {
  [key in ApplicationType]: string;
} = {
  ['IRA']: 'IRA',
  ['SOLO_401K']: 'Solo 401(k) Deposit',
  ['LLC']: 'LLC Deposit',
  ['BUSINESS']: 'Business Deposit',
  ['IRA_DEPOSIT']: 'IRA Deposit',
  ['IRA_LLC_DEPOSIT']: 'IRA LLC Deposit',
  ['TRUST_DEPOSIT']: 'Trust Deposit',
};
