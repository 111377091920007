import { createFileRoute, stripSearchParams } from '@tanstack/react-router';
import { z } from 'zod';
import { Welcome } from '@/features/welcome/Welcome.tsx';

export const Route = createFileRoute('/welcome')({
  head: () => ({ meta: [{ title: 'Welcome' }] }),
  validateSearch: z
    .object({
      continueTo: z.string().optional(),
      continueSearch: z.any().optional(),
    })
    .optional()
    .default({}),
  search: {
    middlewares: [
      stripSearchParams({ continueTo: '/portal/start', continueSearch: {} }),
    ],
  },
  component: Welcome,
});
