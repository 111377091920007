import { ApplicationType } from '@/features/application-filling/types.ts';
import { DepositAccountSlug } from '@/features/portal/start-home/start-new-application-type/types.ts';

export const DEPOSIT_ACCOUNT_TYPES: Record<
  DepositAccountSlug,
  ApplicationType
> = {
  llc: 'LLC',
  'solo-401k': 'SOLO_401K',
  ira: 'IRA',
  trust: 'TRUST_DEPOSIT',
};
