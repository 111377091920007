import { createRouter } from '@tanstack/react-router';
import { trackMatomoPageChange } from '../analytics/matomo.ts';

import { routeTree } from '@/routeTree.gen.ts';
import { FC } from 'react';
import { FallbackRender } from '@sentry/react';

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultViewTransition: true,
});

router.subscribe('onResolved', () => {
  trackMatomoPageChange(router);
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
  interface StaticDataRouteOption {
    previewComponent?: FC;
    previewFallback?: FC;
    previewErrorComponent?: FallbackRender;
  }
}
