import { SingleCardLayout } from '@/components/ui/layout/SingleCardLayout.tsx';
import Spinner from '@/components/ui/Spinner.tsx';

export const ApplicationLoader = () => {
  return <SingleCardLayout>
    <div className={'flex items-center justify-center py-80'}>
      <Spinner className={'size-14'}/>
    </div>
  </SingleCardLayout>
}
