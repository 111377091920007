import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getMigrationQuery } from '@/features/administrator-panel/omnibus/api/migrationsApi.ts';
import { OmnibusMigrationView } from '@/features/administrator-panel/omnibus/migration/OmnibusMigrationView.tsx';

export const Route = createFileRoute(
  '/admin/configuration/omnibus/migration/$migrationId',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getMigrationQuery(params.migrationId)),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData ? `${loaderData.omnibusName} Migration` : 'Migration',
      },
    ],
  }),
  component: OmnibusMigrationView,
});
