import { createFileRoute, useParams } from '@tanstack/react-router';
import { ACHTransfersTable } from '@/features/portal/deposit-accounts/account/transfers/ach/ACHTransfersTable.tsx';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId/home/ach',
)({
  head: () => ({ meta: [{ title: 'ACH' }] }),
  component: () => {
    const { depositAccountId } = useParams({ from: '/portal/deposit-account/$depositAccountId/home/ach' });
    return <ACHTransfersTable depositAccountId={depositAccountId} />;
  }
});
