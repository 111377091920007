import { Bars3Icon } from '@heroicons/react/20/solid/index';
import { Flask } from '@phosphor-icons/react';
import { Link } from '@tanstack/react-router';
import { ReactNode } from 'react';

import { useTheming } from '@/common/hook/useTheming.ts';

type AppBarProps = {
  endSlot?: ReactNode;
  onHamburgerClick?: () => void;
};

const AppBar = ({ endSlot, onHamburgerClick }: AppBarProps) => {
  const { bannerImageDark } = useTheming();
  return (
    <div
      className={
        'dark fixed z-10 flex h-app-bar w-full items-center justify-between bg-primary-foreground'
      }
    >
      {!!onHamburgerClick && (
        <div className="flex items-center lg:hidden">
          <button
            aria-label="Open sidebar"
            type="button"
            onClick={() => onHamburgerClick()}
            className="p-3"
            aria-haspopup="true"
            aria-controls="sidebar"
          >
            <Bars3Icon className="h-6 text-white" />
          </button>
        </div>
      )}
      <div className={'flex h-full items-center justify-start'}>
        <Link to={'/'} className={'ml-5 block h-full p-6 text-white'}>
          <img src={bannerImageDark} className={'h-full'} />
        </Link>
        {import.meta.env.VITE_ENVIRONMENT === 'development' && (
          <div
            className={
              'absolute right-0 top-0 flex items-center rounded-none rounded-bl-md border border-r-0 border-t-0 border-warning-foreground/60 bg-warning p-1 font-mono text-sm text-warning-foreground sm:static sm:rounded-md sm:border-r sm:border-t sm:py-0.5'
            }
          >
            <Flask className={'inline-block size-4 rotate-12 sm:mr-1'} />
            <span className={'hidden sm:inline'}>SANDBOX</span>
          </div>
        )}
      </div>
      <div className={'mr-6 flex min-h-9 min-w-9 items-center justify-end'}>{endSlot}</div>
    </div>
  );
};

export default AppBar;
