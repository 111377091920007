import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getOmnibusAccountQuery } from '@/features/administrator-panel/omnibus/api/omnibusAccountApi.ts';
import { ViewOmnibusAdminPage } from '@/features/administrator-panel/omnibus/view/ViewOmnibusAdminPage.tsx';

export const Route = createFileRoute(
  '/admin/configuration/omnibus/$omnibusAccountId',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      getOmnibusAccountQuery(params.omnibusAccountId),
    ),
  head: ({ loaderData }) => ({
    meta: [{ title: loaderData ? loaderData.name : 'Account' }],
  }),
  component: ViewOmnibusAdminPage,
});
