import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient';

export const getDepositAccountsCount = queryOptions({
  queryFn: async () =>
    (await crownApi.UserDepositAccount_getDepositAccountsCount()).data,
  queryKey: ['user', 'deposit-accounts', 'count'],
  staleTime: 1000 * 60 * 5,
});
