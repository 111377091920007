import { create } from 'zustand';

type CustomersListState = {
  clear: () => void;
  totalItems: number;
  showLoader: boolean;
  setShowLoader: (showLoader: boolean) => void;
  setTotalItems: (totalItems: number) => void;
};

export const useCustomersListState = create<CustomersListState>((set) => ({
  clear: () => void set(useCustomersListState.getInitialState()), // See clearAppState
  totalItems: 0,
  showLoader: false,
  setShowLoader: (showLoader) => set({ showLoader }),
  setTotalItems: (totalItems) => set({ totalItems }),
}));
